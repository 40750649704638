import * as ko from 'knockout';
import * as _ from 'underscore';
import 'pubsub';

import * as $ from "jquery";

import { TypeScreen } from 'Core/Screens/TypeScreen/TypeScreen';
import { BlockUI } from 'Core/Common/BlockUi';

import { TranslationManager } from "Core/Components/Translation/TranslationManager";
import { ITranslationValue } from "Core/Components/TranslationFieldEditor/ITranslationValue";

import {TranslationModel} from "Core/ScreenManager/Models/TranslationModel";
import {SelectedRecordModel} from "Core/ScreenManager/Models/SelectedRecordModel";
import {SelectedEntityRecordModel} from "Core/ScreenManager/Models/SelectedEntityRecordModel";
import {SelectedEntityModel} from "Core/ScreenManager/Models/SelectedEntityModel";
import {ReferenceModel} from "Core/ScreenManager/Models/ReferenceModel";
import {ControlDataModel} from "Core/ScreenManager/Models/ControlDataModel";

import { BaseControl, IControlValue } from 'Core/Controls/BaseControl/BaseControl';
import { AttachedFieldModel } from 'Core/Controls/BaseControl/Models/AttachedFieldModel';
import { IControlParam, IScreen } from 'Core/Screens/IScreen';
import { CONTROL_TYPES, FIELD_TYPES, FONT_NAME, FONT_STYLES, LABEL_POSITIONS, RenderModes, TABLE_TYPES } from 'Core/Constant';
import { FormatManager } from 'Core/Components/FormatManager/FormatManager';
import { GeneralProperties } from 'Core/GeneralProperties/GeneralProperties';
import { FormatConverter } from 'FormatEditor/FormatConverter';
import { FieldFormat, FORMATS } from 'Core/Common/FieldFormat';
import { CONTROL_TYPES_LINK } from 'Core/Controls/Text/Constants';
import { PHONE_NUMBER_LENGTH } from "Core/Controls/Text/PhoneNumberLengths";
import { TranslationFieldEditor } from 'Core/Components/TranslationFieldEditor/TranslationFieldEditor';
import { IconModel } from "../BaseControl/Models/IconModel";
import { Icon } from "Core/Icon/Icon";
import { GlobalManager, GLOBALS } from "Core/GlobalManager/GlobalManager";
import { IntlTelInputExtention } from "Core/KnockoutExtentions/IntlTelInputExtention";
import { PUB_SUB_EVENTS } from 'MenuManager/PubSubEvents';
import { ScreenTypes } from "../../Common/Enums/ScreenTypes";
import { ICreateRecordRequest, IFieldDataModel, RecordStore } from "../../Common/Stores/RecordStore";
import { Notifier } from "../../Common/Notifier";
import { LABELS, NOTIFICATIONS } from 'Core/Components/Translation/Locales';
import { ITooltipConfig } from "../../KnockoutExtentions/TooltipExtention";
import { SipClient } from 'Core/Controls/Text/SipClient';
import { BarcodeScanner } from 'Core/Components/BarcodeScanner/BarcodeScanner';
import { DataModes } from "Core/Enums/DataModes";
import { EVENTS } from 'Core/Screens/Events';
import { DocumentManagerStore } from 'Core/Components/Controls/DocumentManager/Stores/DocumentManagerStore';
import { JsBarcodeExtension } from "../../KnockoutExtentions/JsBarcodeExtension";
import { GENERAL_PROPERTIES } from 'Core/GeneralProperties/Managers/Constants';
import { ZIndexManager } from "../../Common/ZIndexManager";
import { MobileChecker } from "../../Common/MobileChecker";

import TextConfigJson from 'Core/Controls/Text/Configs/text-config.json';

import ViewTemplate from 'Core/Controls/Text/Templates/View.html';
import HelpViewTemplate from 'Core/Controls/Text/Templates/HelpView.html';
import ToolBarTemplate from 'Core/Controls/Text/Templates/ToolBar.html';
import DesignTemplate from 'Core/Controls/Text/Templates/Design.html';
import EditTemplate from 'Core/Controls/Text/Templates/Edit.html';

ko.templates['Core/Controls/Text/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/Text/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/Text/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/Text/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/Text/Templates/Edit'] = EditTemplate;

function getDecimalSize(fieldModel: AttachedFieldModel) {
    if (FieldFormat.IsPercentage(fieldModel.FormatName)) {
        return Math.max(fieldModel.Size - 2, 0);
    }

    return fieldModel.Size;
}

function getIntegerValidationMessage() {
    return LABELS.FILTER_RANGE_ERROR
        .replace('{MinValue}', FormatConverter.LocalizeDecimalOrInteger('-2147483648'))
        .replace('{MaxValue}', FormatConverter.LocalizeDecimalOrInteger('2147483647'));
}

function getDecimalValidationMessage(size: number) {
    return LABELS.DECIMAL_DIGITS_VALIDATION_MESSAGE
        .replace('{decimalDigits}', `${size}`)
        .replace('{totalDigits}', '29')
}

function isInIntegerBounds(value: number) {
    return -2147483648 <= value && value <= 2147483647;
}

interface ICountryData {
    name: string;
    iso2: string;
    dialCode: string;
}

const DEFAULT_COUNTRY_CODE_VALUE = 'nl';

export class Text extends BaseControl {
    private _isNewRecord: boolean;
    private _value: KnockoutObservable<string>;
    private _serverTranslations: TranslationModel[];
    private _labelStyle: KnockoutObservable<any>;
    private _textInputStyle: KnockoutObservable<any>;
    private _textHeight: KnockoutObservable<any>;
    private _originalValue: string;
    private _formatter: KnockoutObservable<FormatManager>;
    private _translationFieldEditor: TranslationFieldEditor;
    private _hasTranslations: KnockoutObservable<boolean>;
    private _hasTemplate: KnockoutObservable<boolean>;
    private _validationMsg: KnockoutObservable<string>;
    private _reference: KnockoutObservable<ReferenceModel>;
    private _phoneCountry: string;
    private _phoneCode: string;
    private _waitForPhoneCode = false;
    private _globalCountry: string;
    private _calculatedDependsOnValue: string;
    private _tooltip: KnockoutObservable<ITooltipConfig>;
    private _referenceConsultScreen: KnockoutObservable<IScreen>;
    private _isHyperlink: KnockoutObservable<boolean>;
    private _dataRecordId: number;
    private _isMobile: MobileChecker;

    constructor(params: IControlParam) {
        super(params, TextConfigJson);

        this._value = ko.observable(null);

        this.Init();
        this.BindEvents();

        this._labelStyle = ko.observable(null);
        this._textHeight = ko.observable(null);
        this._textInputStyle = ko.observable({});
        this._formatter = ko.observable(null);
        this._hasTemplate = ko.observable(null);
        this._validationMsg = ko.observable(this._labels.THIS_FIELD_IS_REQUIRED);
        this._reference = ko.observable(null);
        this._referenceConsultScreen = ko.observable(null);
        this._isVisible = ko.observable(true);
        this._isHyperlink = ko.observable(false);
        this._isMobile = MobileChecker.IsMobile();

        this.SetPhoneCountry();
        this.SetPhoneCode();

        this._translationFieldEditor = new TranslationFieldEditor();
        this._translationFieldEditor.On('TranslationSelected', this, eventArgs => this.ChangeTranslation(eventArgs.data));

        this._hasTranslations = ko.observable(false);

        this.ApplyProperties();

        const screen = this._form && this._form.GetScreen();
        if (screen) {
            screen.On(EVENTS.DATA_CHANGED, this, (evt) => {
                if (evt.data && evt.data.ScreenVariable && this.FieldModel.FilledById != 0) {

                    let dynamicField = _.find(this.FieldModel.DynamicFields, (field) => {
                        return field === evt.data.ScreenVariable.Field.Id
                    });

                    if (dynamicField && !this._resetDependsOnValue()) {
                        this.UpdateDependsOnValue();
                    }
                }
            });
        }
        this.ApplyFormatter();

        this._tooltip = ko.observable(null);

        this.GetTemplateName = ko.computed(() => {
            if(this._isHideIfDataLoading()){
                return 'Core/Controls/BaseControl/Templates/DataLoadTemplate';
            }

            if (this._renderMode() === RenderModes.Design) {
                if (this._model().Predefined) {
                    return 'Core/Controls/BaseControl/Templates/PredefinedDesignWrapper';
                }

                if (this._isStatic) {
                    return 'Core/Controls/BaseControl/Templates/StaticDesignWrapper';
                }

                return 'Core/Controls/BaseControl/Templates/DesignWrapper';
            }

            if (this.FieldModel && this.FieldModel.FieldTypeName === FIELD_TYPES.Reference && this._renderMode() === RenderModes.Edit) {
                return 'Core/Controls/Text/Templates/View';
            }

            let template = RenderModes[this._renderMode()];
            return `Core/Controls/${this.GetType()}/Templates/${template}`;
        });
    }

    get IsPercentageIcon() : boolean {
        return !!(this._value() && this._value().length !== 0 && this.FieldModel.FormatName === 'Percentage');
    }

    get IsBarcodeFormat(): boolean {
       return this.FieldModel.FormatName === "Barcode";
    }

    get IsDependsOn(): boolean {
        return this.EnableResetDependOnValue && this._resetDependsOnValue();
    }

    private UpdateDependsOnValue() {
        this.CalculateDependOnValue()
            .then(dependsOnValue => {
                this._calculatedDependsOnValue = this.FormatPercentageValue(dependsOnValue);
                this._calculatedDependsOnValue = this.ApplyCulture(this._calculatedDependsOnValue);
                this._value(this._calculatedDependsOnValue);
                if (this._resetDependsOnValue()) {
                    this._resetDependsOnValue(false);
                }
            });
    }

    private SetPhoneCode() {
        const global = GlobalManager.Instance.GetGlobal(GLOBALS.DEFAULT_COUNTRY_CODE).toLowerCase();
        this._phoneCode = IntlTelInputExtention.GetCodeByIso2(global);
    }

    private SetPhoneCountry() {
        const global = GlobalManager.Instance.GetGlobal(GLOBALS.DEFAULT_COUNTRY_CODE).toLowerCase();
        if (IntlTelInputExtention.IsCountryValid(global)) {
            this._globalCountry = global;
        } else {
            this._globalCountry = DEFAULT_COUNTRY_CODE_VALUE;
        }
    }

    ApplyProperties() {
        if (this.Properties) {
            if (this.Properties.Label) {
                const labelStyle = { backgroundColor: null, color: null };

                _.each(this.Properties.Label.Properties, (property: any) => {
                    if (property.BackgroundColor) {
                        labelStyle.backgroundColor = property.BackgroundColor;
                    }

                    if (property.Color) {
                        labelStyle.color = property.Color;
                    }
                });
                this._labelStyle(labelStyle);
            }

            if (this.Properties.BackgroundColor) {
                const backgroundColor = this.Properties.BackgroundColor;
                this._backgroundColor(backgroundColor);
            }

            if (this.Properties.TextInput) {
                const textInputStyle = { backgroundColor: null };

                _.each(this.Properties.TextInput.Properties, (property: any) => {
                    if (property.BackgroundColor) {
                        textInputStyle.backgroundColor = property.BackgroundColor;
                    }
                });

                this._textInputStyle(textInputStyle);
            }

            if (this.Properties.Height) {
                _.each(this.Properties.Height.Properties, (property: any) => {
                    if (property.Height) {
                        this._textHeight(property.Height.Value);
                    }
                });
            }
        }

        if (this._form && (this._form.GetScreen().GetTypeName() === ScreenTypes[ScreenTypes.LinkEditor])) {
            this.ApplyLinkEditorStyles();
        }

        if(this.HideIfCondition){
            if(this.IsRunTime){
                this._isVisible(false);
            }
        }
    }

    private ApplyLinkEditorStyles() {
        if (!this.FieldModel.HasLinkEditorVisibility) {
            return;
        }
        const labelStyle = {
            color: null,
            fontWeight: null,
            fontStyle: null,
            textDecoration: null
        };
        if (this.FieldModel.FontColor) {
            labelStyle.color = this.FieldModel.FontColor;
        }
        labelStyle.fontWeight = FONT_STYLES.NORMAL;

        if (this.FieldModel.FontStyles) {
            _.forEach(this.FieldModel.FontStyles, (style) => {

                switch (style.Name.toLowerCase()) {
                    case FONT_STYLES.BOLD:
                        labelStyle.fontWeight = FONT_STYLES.BOLD;
                        break;
                    case FONT_STYLES.UNDERLINE:
                        labelStyle.textDecoration = FONT_STYLES.UNDERLINE;
                        break;
                    case FONT_STYLES.ITALIC:
                        labelStyle.fontStyle = FONT_STYLES.ITALIC;
                        break;
                }
            })
        }

        this.Extend(labelStyle, this._labelStyle());
        this._labelStyle(labelStyle);
    }

    private Init(): void {
        this.GetFieldMaxLength();
    }

    private BindEvents() {
        this._model.subscribe(() => this.Init());
    }

    private InitHyperLink(data: ControlDataModel) {
        if (data) {
            this._isHyperlink(
                this._form &&
                this._form.GetScreen().GetTypeName() === ScreenTypes[ScreenTypes.ConsultScreen] &&
                this.FormatName === 'None' &&
                this.FieldModel.FieldTypeName === FIELD_TYPES.Text &&
                this.FieldModel.EntityId !== this._form.GetScreen().GetEntityId() &&
                this.FieldModel.EntityTypeName === TABLE_TYPES.Entity &&
                data.RecordId &&
                (data.DisplayValue || data.Value)
            );
        }
    }

    getPhoneNumberMinAndMaxLength(iso2: string) {
        let min, max;
        PHONE_NUMBER_LENGTH.map(function (el) {
            if (el.iso2 == iso2 && el.iso2 != undefined && el.iso2 != null) {
                min = el.min, max = el.max;
            }
        });
        return { min, max };
    }

    SetData(data: ControlDataModel) {
        this._dataRecordId = data.RecordId;
        let currentValue = data.DisplayValue || data.Value;

        this.InitHyperLink(data);

        currentValue = this.FormatPercentageValue(currentValue);

        let currentDefaultValue = currentValue;

        if (this.IsTranslatable()) {
            this._serverTranslations = data.Translations;

            const translation = this.GetTranslation(data);
            currentValue = translation.TranslatedValue || translation.Value;

            this._translationFieldEditor.LoadTranslationItems();
            this._translationFieldEditor.SetTranslations(data.Translations, data.DisplayValue);
            this._translationFieldEditor.SetTranslationMaxSize(this.GetFieldMaxLength());
            this._translationFieldEditor.SetActiveTranslation(translation.Language.Id);

            this._hasTranslations(true);
        }

        currentValue = this.ApplyCulture(currentValue);
        currentDefaultValue = this.ApplyCulture(currentDefaultValue);

        if (this.FormatName == FORMATS.PHONE_NUMBER && currentValue) {
            currentValue = `+${currentValue.replace(/\D/g, '')}`;
            currentDefaultValue = `+${currentDefaultValue.replace(/\D/g, '')}`;

            this._waitForPhoneCode = true;
        }

        this._value(currentValue);
        this._originalValue = currentDefaultValue;

        if (this.GetFieldModel() && this.GetFieldModel().FieldTypeName === FIELD_TYPES.Reference) {
            this.SetReferenceData(data.Reference);
        }
        this.ApplyFormatter();
    }

    private FormatPercentageValue(value: string){
        if (this.FieldModel
            && this.FieldModel.FieldTypeName === FIELD_TYPES.Decimal
            && FieldFormat.IsPercentage(this.FieldModel.FormatName)
            && this.IsModified && value) {
            return ((value as any).replace(',', '.') * 1 * 100).toFixed((this.FieldModel.Size < 2) ? 0 : this.FieldModel.Size - 2);
        }
        return value;
    }

    SetValue(value: IControlValue): void {
        if (!value.Data) return;

        this._isNewRecord = value.RecordSpecsModel && value.RecordSpecsModel.IsNewRecord;

        this.SetData(value.Data);

        this._value.subscribe((newValue) => {

            this._translationFieldEditor.SetValue(newValue);

            if (this.FormatName == FORMATS.PHONE_NUMBER && newValue && newValue[0] === '+') {
                this._value(newValue.replace('+' + this._phoneCode, ''));
            }

            if (this._calculatedDependsOnValue && this.IsEqual(newValue, this._calculatedDependsOnValue)) {
                this._resetDependsOnValue(true);
            }

            let textarea = $(this._el).find('.input-group').find('textarea')[0];

            if(textarea?.hasOwnProperty('inputmask')){
                $(textarea).trigger("keyup");
            };

            this.UpdateVariable({ Field: this.GetFieldModel(), ControlType: CONTROL_TYPES.Text }, this._value());

            this.ApplyFormatter();
        });

        this._value.extend({ rateLimit: { timeout: 500, method: 'notifyWhenChangesStop' } });
    }

    IsEqual(value1: string, value2: string): boolean {
        if(FieldFormat.IsDecimal(this.FieldModel.FieldTypeName)){
            let val = parseFloat(value1?.replace(',', '.')) - parseFloat(value2?.replace(',', '.'));
            return val != 0    
        }
        return value1 != value2;
    }

    OpenRecordScreen(_, data, evt) {
        const isOpenInModal: boolean = evt.ctrlKey || _._screen.IsInModal();
        PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, { EntityId: this.FieldModel.EntityId, RecordId: this._dataRecordId, IsOpenInModal: isOpenInModal });
    }

    OpenEditScreen(text: Text, evt) {
        const entityRecord = this._reference() && this._reference().SelectedEntityRecord;
        const referenceTable = this._reference() && this._reference().ReferenceTable;

        if (entityRecord && entityRecord.IsValid) {
            let options = {
                EntityId: entityRecord.SelectedEntity.Id,
                RecordId: entityRecord.SelectedRecord.Id,
                IsOpenInModal: evt.ctrlKey || this._form.GetScreen().IsInModal(),
                Owner: this._form.GetScreen()
            };

            PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, options);
        }

        if (referenceTable && !entityRecord && this._renderMode() === RenderModes.Edit) {
            this.CreateRecord(referenceTable.Id);
        }

        return true;
    }

    CreateRecord(entityId: number) {
        const typeScreen = new TypeScreen(entityId, 0, true);
        typeScreen.On('TYPES_NOT_FOUND', this, (eventArgs) => {
            new Notifier().Warning(eventArgs.data.Message || NOTIFICATIONS.TYPES_NOT_FOUND);
        });
        typeScreen.On('TYPE_SELECTED', this, (eventArgs) => {
            let eventArgsData = eventArgs.data;
            this.NewRecord(entityId, eventArgsData.TypeId, eventArgsData.TypeName, eventArgsData.KindId, eventArgsData.ExampleRecordId);
        });
        typeScreen.Show();
    }


    NewRecord(
        entityId: number,
        tableTypeId: number,
        tableTypeName: string,
        kindId: number,
        exampleRecordId: number
    ) {
        let fields: Array<IFieldDataModel> = [
            { Name: 'F_TYPE', Value: tableTypeId.toString() },
            { Name: 'F_KIND', Value: kindId.toString() }];

        let createRecordRequest: ICreateRecordRequest = {
            TableId: entityId,
            RequiredValidation: false,
            Fields: fields,
            ExampleRecordId: exampleRecordId ? exampleRecordId : null
        };

        RecordStore.CreateRecord(createRecordRequest).then((recordId) => {
            const notifier = new Notifier($(this._el));
            notifier.Success(NOTIFICATIONS.RECORD_CREATED);

            let selectedEntityRecord = new SelectedEntityRecordModel();
            let selectedRecord = new SelectedRecordModel();
            selectedRecord.Id = recordId;
            selectedRecord.Memo = '';
            selectedRecord.TypeName = tableTypeName;
            selectedEntityRecord.SelectedRecord = selectedRecord;
            let selectedEntityModel = new SelectedEntityModel();
            selectedEntityModel.Id = this._reference().ReferenceTable.Id;
            selectedEntityModel.Icon = this._reference().ReferenceTable.EntityIcon;
            selectedEntityModel.Name = this._reference().ReferenceTable.Name;
            selectedEntityModel.Translation = this._reference().ReferenceTable.Translation;
            selectedEntityRecord.SelectedEntity = selectedEntityModel;
            this._reference().Name = '';
            this._reference().RecordId = recordId;
            this._value('');
            this._reference().IsModified = true;
            this._reference().SelectedEntityRecord = selectedEntityRecord;
            this.SetReferenceDataForEditMode();
            this.LoadReferenceConsultScreen();
        }).fail((err) => {
            let notifier = new Notifier(null);
            notifier.Failed(JSON.parse(err.message).Message);
        });
    }

    private GetClassName(): string {
        const classes = [this.FormatName, this._textHeight(), this._isIconVisible && 'with-icon'];

        if (this._reference()) {
            if (this._reference().SelectedEntityRecord ||
                (this._renderMode() === RenderModes.Edit && this._reference().ReferenceTable && this._reference().ReferenceTable.IsValid)) {
                classes.push('marker-link');
            }
        }

        return classes.join(' ');
    }

    private SetInvalidIcon() {
        let iconModel = new IconModel();
        iconModel.IsImage = false;
        iconModel.IsIcon = true;
        iconModel.FontName = FONT_NAME.FONT_AWESOME;
        iconModel.Name = 'fa-times';

        this._icon(new Icon(iconModel));
    }

    private SetIcon(icon: IconModel) {
        this._icon(new Icon(icon));
    }

    SetReferenceData(reference: ReferenceModel) {
        this._reference(reference);

        if (!reference) {
            this.SetInvalidIcon();
            this._value(NOTIFICATIONS.REFERENCE_TABLE_NOT_SET);
            return;
        } else {
            if (reference.SelectedEntityRecord && !reference.SelectedEntityRecord.SelectedEntity) {
                this.SetInvalidIcon();
                this._value(NOTIFICATIONS.TABLE_NOT_FOUND);
                return;
            } else if (reference.SelectedEntityRecord && !reference.SelectedEntityRecord.SelectedRecord) {
                this.SetInvalidIcon();
                this._value(NOTIFICATIONS.RECORD_NOT_FOUND);
                return;
            } else if (reference.ReferenceTable && !reference.ReferenceTable.IsValid) {
                this.SetInvalidIcon();
                this._value(NOTIFICATIONS.INVALID_REFERENCE_TABLE);
                return;
            } else {
                if (this._renderMode() === RenderModes.View) {
                    this.SetReferenceDataForViewMode();
                    return;
                }

                if (this._renderMode() === RenderModes.Edit) {
                    this.SetReferenceDataForEditMode();
                    this.LoadReferenceConsultScreen();
                    return;
                }
            }
        }
    }

    private SetReferenceDataForViewMode() {
        const entityRecord = this._reference().SelectedEntityRecord;
        const referenceTable = this._reference().ReferenceTable;

        if (referenceTable && referenceTable.IsValid) {
            this.SetInvalidIcon();
            this._value(LABELS.NO_RECORD);
            return;
        }

        if (entityRecord) {
            this.Label(entityRecord.SelectedEntity.Translation || entityRecord.SelectedEntity.Name);
            this.SetIcon(entityRecord.SelectedEntity.Icon);
            if (entityRecord.SelectedRecord.Name) {
                this._value(entityRecord.SelectedRecord.Name)
            } else {
                this._value('<i class="fa fa-external-link"></i>');
            }
        }
    }

    private SetReferenceDataForEditMode() {
        const entityRecord = this._reference().SelectedEntityRecord;
        const referenceTable = this._reference().ReferenceTable;

        if (!entityRecord && referenceTable) {
            this._value(`${this._labels.CREATE}...`);
            this.Label(referenceTable.Translation || referenceTable.Name);
            this.SetIcon(referenceTable.EntityIcon);
            return;
        } else if (entityRecord) {
            if (this._form.GetScreen().IsLinkEditor) {
                this.AddStyle('display', 'none');
            }
            this.Label(entityRecord.SelectedEntity.Translation || entityRecord.SelectedEntity.Name);
            this.SetIcon(entityRecord.SelectedEntity.Icon);
            this._value(entityRecord.SelectedEntity.Translation || entityRecord.SelectedEntity.Name);
            if (entityRecord.SelectedRecord.Name) {
                this._value(entityRecord.SelectedRecord.Name);
            } else {
                this._value('<i class="fa fa-external-link"></i>');
            }
        }
    }

    ApplyCulture(value: string): string {
        if (!!value && this.FieldModel && this.FieldModel.FieldTypeName === FIELD_TYPES.Decimal) {
            return FormatConverter.ConvertDecimal(value);
        }

        return value;
    }

    AfterRender(elements: Array<HTMLElement>): void {
        super.AfterRender(elements);
        this._el = _.find(elements, (element) => element.nodeType === 1);
        if (!this.FieldModel) return;

        const formatName = this.FieldModel.FormatName;
        if ((FieldFormat.IsDecimal(formatName) ||
            FieldFormat.IsCurrency(formatName) ||
            FieldFormat.IsPercentage(formatName) ||
            this.FieldModel && this.FieldModel.FieldTypeName === FIELD_TYPES.Integer)
            && (this._renderMode() === RenderModes.Edit)) {
            this.AddEventListenerChangeValue(elements);
        }

        this._tooltip({
            content: this.LineBreak || this.Reference ? this.FormatDisplayValue : _.escape(this.FormatDisplayValue),
            onlyOnOverflow: true,
            addClass: 'ellipsis-tooltip',
        })
    }

    AddEventListenerChangeValue(parentEl) {
        const textarea = $(parentEl).find('.es-textarea-control')[0];

        if (!textarea) return;

        textarea.onkeyup = () => {
            const actualValue: any = $(textarea).val();

            if (!actualValue.length) {
                this._value(actualValue);
            }
        };
    }

    Deserialize() {
        if (this.FormatName === FORMATS.PHONE_NUMBER && this._phoneCode && this.IsModified && this._value()) {
            this._value(this._phoneCode + '-' + this._value().replace(/\D/g, '').replace('-', ''));
        }

        const undoPhoneChange = () => {
            const value = this._value();
            if (this.FormatName === FORMATS.PHONE_NUMBER && this._phoneCode && this.IsModified && value) {
                const dashPosition = value.indexOf('-');
                this._value(value.substr(dashPosition + 1));
            }
        };

        if (this.FieldModel
            && this.FieldModel.FieldTypeName === FIELD_TYPES.Decimal
            && FieldFormat.IsPercentage(this.FieldModel.FormatName)
            && this.IsModified && this._value()) {
            this._value(String((parseFloat(
                this._value().replace(',', '.')) / 100)
                .toFixed(this.FieldModel.Size)));
        }

        if (this.FieldModel && this.FieldModel.FieldTypeName === FIELD_TYPES.Reference) {
            if (this._reference() && (this._reference().IsModified)) {
                if (!this._reference()) {
                    this._value(null);
                }
                if (this._reference().ReferenceTable) {
                    this._value(`${this._reference().ReferenceTable.Id}`);
                }
                if (this._reference().SelectedEntityRecord) {
                    this._value(`${this._reference().SelectedEntityRecord.SelectedEntity.Id};${this._reference().SelectedEntityRecord.SelectedRecord.Id}`);
                }
            } else {
                return null;
            }
        }

        const field = _.first(this._model().Fields);

        this.RemoveNotAllowedSymbols();

        if (!this._isReadonly && field) {
            let serializedValue = [`${field.EntityName}.${field.Name}`];

            if (!this.IsTranslatable()) {
                serializedValue.push(this._value());
            } else {
                const defaultTranslation = this._translationFieldEditor.GetDefaultTranslation().Value;
                serializedValue.push(defaultTranslation);

                const activeTranslations = this._translationFieldEditor.GetTranslations(false);
                const changedTranslations = activeTranslations.map(translation => {
                    const languageShortName = TranslationManager.Instance.GetLanguageById(translation.LanguageId).ShortName;

                    return !translation.Value ? `${languageShortName}_` : `${languageShortName}_${translation.Value}`;
                });

                serializedValue.push(...changedTranslations);
            }

            undoPhoneChange();

            return serializedValue;
        }

        undoPhoneChange();

        return null;
    }

    IsModified(): boolean {
        if (super.IsModified() || this._isNewRecord) {
            return true;
        }

        if (!this.IsTranslatable()) {
            return this._value() !== this._originalValue;
        }

        const defaultTranslation = this._translationFieldEditor.GetDefaultTranslation().Value;
        const activeTranslations = this._translationFieldEditor.GetTranslations(false);

        const translationDiffExists = _.any(activeTranslations, activeTranslation => {
            const serverTranslation = _.find(this._serverTranslations, serverTranslation => serverTranslation.LanguageId === activeTranslation.LanguageId);
            return serverTranslation && activeTranslation.Value !== serverTranslation.Value;
        });

        return defaultTranslation !== this._originalValue || translationDiffExists;
    }

    GetValue(): any {
        return this._value();
    }

    get Value(): any {
        return this._value();
    }

    GetTranslations() {
        return this._translationFieldEditor.GetTranslations(false);
    }

    GetDefaultTranslation() {
        return this._translationFieldEditor.GetDefaultTranslation();
    }

    IsValid(): boolean {
        if (this.GetFieldModel() && this.GetFieldModel().IsVirtual) {
            this._isValid(true);
            return true;
        }

        this.SetDefaultValueByTranslation();
        if (this._isRequired && this._hasTranslations()) {

            let defaultItem = _.find(this._translationFieldEditor.TranslationItemsList, (item) => {
                return item.Language.IsDefault;
            });

            if (defaultItem && defaultItem.Value()) {
                this._isValid(true);
            } else {
                this._isValid(false);
            }
        } else {
            if (this._isRequired && (!this._value() || this._value() === '')) {
                this._isValid(false);
            } else if (this.FormatName === FORMATS.PHONE_NUMBER) {
                const value = this._value();
                const formattedValue = this.GetFormattedPhoneNumber(value ? value.replace(/\D/g, '') : '');

                if (
                    formattedValue &&
                    intlTelInputUtils.getValidationError(formattedValue, this._phoneCountry ?? this._globalCountry)
                ) {
                    let errorCode = parseInt(intlTelInputUtils.getValidationError(formattedValue, this._phoneCountry ?? this._globalCountry));
                    let { min, max } = this.getPhoneNumberMinAndMaxLength(this._phoneCountry);

                    if (errorCode === intlTelInputUtils.validationError.TOO_SHORT) {
                        this._validationMsg(`${NOTIFICATIONS.MINIMUM_LENGTH_OF_THIS_FIELD} ${min}`);
                    } else if (errorCode === intlTelInputUtils.validationError.TOO_LONG) {
                        this._validationMsg(`${NOTIFICATIONS.MAXIMUM_LENGTH_OF_THIS_FIELD} ${max}`);
                    } else if (errorCode == intlTelInputUtils.validationError.NOT_A_NUMBER) {
                        this._validationMsg(`${NOTIFICATIONS.MINIMUM_LENGTH_OF_THIS_FIELD} ${min}`);
                    }
                    this._isValid(false);
                } else {
                    this._isValid(true);
                }
            } else if (this.FormatName === FORMATS.DUTCH_POSTAL_CODE && this._isRequired) {
                let formattedValue = this._value() ? this._value().replace(/[^0-9\sA-Z]/g, '') : '';
                if (formattedValue.length < 7) {
                    this._validationMsg(LABELS.DUTCH_POSTAL_CODE_MUST_BE_FULFILLED);
                    this._isValid(false);
                }
            } else if (this.FieldModel && this.FieldModel.FieldTypeName === FIELD_TYPES.Integer) {
                const value = this._value();
                if (value === undefined || value === null || value === '') {
                    this._isValid(true);
                } else {
                    if (isInIntegerBounds(Number(value))) {
                        this._isValid(true);
                    } else {
                        this._validationMsg(getIntegerValidationMessage());
                        this._isValid(false);
                    }
                }
            } else if (this.FieldModel && this.FieldModel.FieldTypeName === FIELD_TYPES.Decimal) {
                const value = this._value();
                if (value === undefined || value === null || value === '') {
                    this._isValid(true);
                } else {
                    const [integerPart, decimalPart = ''] = value.replace('-', '').split(/[.,]/);

                    const size = getDecimalSize(this.FieldModel);

                    if (decimalPart.length <= size && integerPart.length + decimalPart.length <= 29) {
                        this._isValid(true);
                    } else {
                        this._validationMsg(getDecimalValidationMessage(size));
                        this._isValid(false);
                    }
                }
            } else {
                this._isValid(true);
            }
        }
        return this._isValid();
    }

    ValidateTranslation(translationValue: string) {
        const fieldMaxLength = this.GetFieldMaxLength()
        if (translationValue && fieldMaxLength > 0 && translationValue.length >= fieldMaxLength) {
            this._isValid(false);
            this._validationMsg(`${NOTIFICATIONS.MAXIMUM_LENGTH_OF_THIS_FIELD} ${fieldMaxLength}`);
        } else {
            this._isValid(true);
            this._validationMsg(this._labels.THIS_FIELD_IS_REQUIRED);
        }
    }

    SetDefaultValueByTranslation() {
        if (this._isRequired && this._hasTranslations()) {

            let defaultItem = _.find(this._translationFieldEditor.TranslationItemsList, (item) => {
                return item.Language.IsDefault;
            });

            if (defaultItem && !defaultItem.Value()) {

                let currentLang = TranslationManager.Instance.GetCurrentLanguage();
                let currentLangItem = _.find(this._translationFieldEditor.TranslationItemsList, (item) => {
                    return currentLang.Id === item.Language.Id;
                });

                if (currentLangItem.Value()) {
                    defaultItem.Value(currentLangItem.Value());
                } else {
                    let first = _.first(this._translationFieldEditor.TranslationItemsList);
                    if (first) {
                        defaultItem.Value(first.Value());
                    }
                }
            }
        }
    }

    OnPastePhoneNumber(type, ev) {
        let pastedText = ev.originalEvent.clipboardData.getData('text');
        pastedText = pastedText.replace(/\D/g, '');
        this._value(this.GetFormattedPhoneNumber(pastedText));
        return true;
    }

    GetFormattedPhoneNumber(value: string): string {
        if (value.startsWith('0')) {
            return value.substr(1);
        }

        const withoutPlus = value.replace('+', '');
        const withoutCode = withoutPlus.startsWith(this._phoneCode) ? withoutPlus.replace(this._phoneCode, '') : withoutPlus;

        if (withoutCode.startsWith('0')) {
            return withoutCode.substr(1);
        }

        return withoutCode;
    }

    OnKeyUpValidation(that, event): boolean {
        if (FormatManager.IsSocialFormat(this.FormatName)) {
            const value = this._value();
            const formattedUrl = FormatManager.FormatUrlForSocial(value);
            if (formattedUrl) {
                this._value(formattedUrl);
                this._isValid(true);
            } else {
                this._isValid(false);
                this._validationMsg(`${NOTIFICATIONS.INCORRECT_URL_PLEASE_TYPE_FULL_URL_LIKE}`);
            }
            return this._isValid();
        }

        if (this._value() && this.GetFieldMaxLength() > 0 && this._value().length >= this.GetFieldMaxLength()) {
            this._isValid(false);
            this._validationMsg(`${NOTIFICATIONS.MAXIMUM_LENGTH_OF_THIS_FIELD} ${this.GetFieldMaxLength()}`);
        } else if (this._value() && this.FieldModel.FieldTypeName === FIELD_TYPES.Integer && !isInIntegerBounds(Number(this._value()))) {
            this._isValid(false);
            this._validationMsg(getIntegerValidationMessage());
        } else if (this._value() && this.FieldModel.FieldTypeName === FIELD_TYPES.Decimal && this._value().replace(/[-.,]/g, '').length > 29) {
            const size = getDecimalSize(this.FieldModel);

            this._isValid(false);
            this._validationMsg(getDecimalValidationMessage(size));
        } else {
            this._isValid(true);
            this._validationMsg(this._labels.THIS_FIELD_IS_REQUIRED);
        }

        this.FormatEditableValue(event);
        return this._isValid();
    }

    private FormatUrl(value): string {
        return value() && value().includes('://') ? value() : `http://${value()}`;
    }

    ApplyFormatter() {
        if (this.GetRenderMode() === RenderModes.View) {
            let screen = this.GetForm().GetScreen();

            this._formatter(new FormatManager({
                Screen: this.Screen,
                Icon: this.GetIconNameForLinkType(),
                Value: this._value(),
                Format: this.FormatName,
                TableId: screen.GetEntityId(),
                RecordId: screen.GetRecordId(),
                Label: this._model().Name,
                LabelPosition: this._model().LabelPosition,
                LabelStyle: this._labelStyle
            }));
            this._hasTemplate(!!this._formatter() && this._formatter().HasTemplate);
        }
    }

    IsSocialViewActive(): boolean {
        return (FormatManager.IsSocialFormat(this.FormatName)
            || FormatManager.IsSkypeFormat(this.FormatName))
            && this._hasTemplate();
    }

    IsLinkTypeControl(): boolean {
        return CONTROL_TYPES_LINK[this.FormatName];
    }

    IsBarcodeButtonVisible() {
        return FieldFormat.IsBarcode(this.FormatName) && !this.FieldModel.IsVirtual && !this.GetCombinedReadOnly();
    }

    IsBarcodeValid() {
        return JsBarcodeExtension.Validate(this.FormatDisplayValue);
    }

    GetTooltipContent() {
        let tooltipContent = null;

        if (this.FormatDisplayValue) {
            tooltipContent = this._labels.VALUE_CANNOT_BE_CONVERTED_TO_BARCODE;
        } else {
            tooltipContent = this._labels.EMPTY;
        }

        return tooltipContent;
    }

    GetIconNameForLinkType(): string {
        return CONTROL_TYPES_LINK[this.FormatName] ? CONTROL_TYPES_LINK[this.FormatName].icon : null;
    }

    FormatEditableValue = (event: { target: HTMLTextAreaElement }) => {
        const value = event.target.value;
        this._value(this.ModifyValueByFormat(value));
    };

    ModifyValueByFormat(value: string) {
        let result: string;
        if (FieldFormat.IsUppercase(this.FormatName)) {
            result = value.toUpperCase();
        } else if (FieldFormat.IsLowercase(this.FormatName)) {
            result = value.toLowerCase();
        } else if (FieldFormat.IsStartswithcapital(this.FormatName)) {
            if (value.length > 0) {
                result = value.charAt(0).toUpperCase() + value.slice(1);
            } else {
                result = value;
            }
        } else {
            result = value;
        }
        return result;
    };

    get LineBreak(): boolean {
        const value = this._value();
        if (value == null) {
            return null;
        }
        const preparedValue = value.toString().replace(/(?:\r\n|\r|\n)/g, '<br>');
        return preparedValue.indexOf('<br>') !== -1;
    }

    get Reference(): boolean {
        return !!this._reference();
    }

    get FormatDisplayValue(): string {
        const value = this._value();
        if (value == null) {
            return value;
        }

        const preparedValue = value.toString().replace(/(?:\r\n|\r|\n)/g, '<br>');

        const formattedValue = this.ModifyValueByFormat(preparedValue);

        const lineBreak = preparedValue.indexOf('<br>') !== -1;

        if (
            _.contains([FIELD_TYPES.Decimal, FIELD_TYPES.Integer], this.FieldModel.FieldTypeName) &&
            this.FieldModel.FormatName !== FORMATS.PHONE_NUMBER
        ) {
            return FormatConverter.LocalizeDecimalOrInteger(formattedValue);
        }

        return lineBreak ? formattedValue.replace(/<(?!br\s*\/?)[^>]+>/g, '') : formattedValue;
    }

    ToggleTranslations() {
        this._translationFieldEditor.Toggle();
    }

    ChangeTranslation(translation: ITranslationValue) {
        const value = this.ApplyCulture(translation.Value);
        this._value(value);
        this.ApplyFormatter();
        this.ValidateTranslation(value);
    }

    RemoveNotAllowedSymbols() {
        if (this.FieldModel.FormatName !== FORMATS.PHONE_NUMBER) {
            return;
        }

        const regex = /(\(_\)|_|\(\)|-(?=\D))/gi;

        const result = this._value() &&
            this._value()
                .split(regex)[0]
                .replace(' ', '');

        this._value(result);
    }

    GetFieldMaxLength(): number {
        const fieldData = this._model().Fields[0];
        if (!fieldData) {
            return;
        }

        const maxLength = (fieldData.FieldTypeName === FIELD_TYPES.Decimal) ? -1 : fieldData.Size;

        return maxLength > 0 ? maxLength : -1;
    }

    OnPhoneListener(countryData: ICountryData, resetValue: boolean) {
        if (resetValue) {
            this._value('');
        }
        this._phoneCountry = countryData.iso2;
        this._phoneCode = countryData.dialCode;

        if (this._waitForPhoneCode) {
            this._value(this.GetFormattedPhoneNumber(this._value()));
            this._originalValue = this.GetFormattedPhoneNumber(this._originalValue);

            this._waitForPhoneCode = false;
        }
    }

    OpenBarcodeScanner() {
        const barcodeScanner = new BarcodeScanner();

        barcodeScanner.On('SUBMIT', this, eventArgs => {
            this._value(eventArgs.data.value);
        });

        barcodeScanner.Show();
    }

    MakeCall() {
        //SipClient.Instance.MakeCall(this._value());
    }

    GetPhoneCode(): string {
        return this._phoneCode;
    }

    ResetDependOnValue() {
        this.UpdateDependsOnValue();
    }

    async LoadReferenceConsultScreen() {
        if (this._reference() && this.Screen.IsLinkEditor) {
            let referenceTable = this.GetReferenceTable();
            let referenceRecord = this.GetReferenceRecord();

            if (!!referenceTable && !!referenceRecord) {
                BlockUI.Block({ Target: this._el });
                const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;

                screenManager.GetScreenByScreenType(
                        referenceTable,
                        ScreenTypes.ConsultScreen,
                        referenceRecord,
                        DataModes.Default,
                        this.Screen.IsLinkEditor
                    )
                    .always(() => {
                        BlockUI.Unblock(this._el);
                    })
                    .then((screen: IScreen) => {
                        screen.RecordOpenInPopUp(!!this._reference(), this.Screen.IsLinkEditor);
                        screen.SetIsReady(true);
                        screen.IsReferenceScreen = true;
                        screen.On('REFRESH_REFERENCE_SCREEN', this, ()=> this.LoadReferenceConsultScreen());
                        this._referenceConsultScreen(screen);
                    }).fail(err => new Notifier().Warning(err.message));
            }
        }
    }

    private GetReferenceTable() {
        return this._reference().SelectedEntityRecord ? this._reference().SelectedEntityRecord.SelectedEntity.Id : this._reference().ReferenceTable.Id;
    }

    private GetReferenceRecord() {
        return this._reference().SelectedEntityRecord ? this._reference().SelectedEntityRecord.SelectedRecord.Id : this._reference().RecordId;
    }

    get ReferenceConsultScreen(): KnockoutObservable<IScreen> {
        return this._referenceConsultScreen;
    }

    set BackgroundColor(value: string){
        let textInputStyle = { backgroundColor: value };
        this._textInputStyle(textInputStyle);
    };

    get BackgroundColor(): string{
       return this._textInputStyle() && this._textInputStyle().backgroundColor;
    };

    PrintBarCode(){
        DocumentManagerStore.PrintLabel({ Barcode: this._value() }).fail(err=>new Notifier().Failed(err.message));
    }

    ForceFocus(){
        let textareaElement = $(this._el).find('.input-group').find('textarea');

        setTimeout(() => {
            textareaElement.click();
        }, 200);

        textareaElement.one('click', ()=> {
            textareaElement.focus();
        })
    }
}